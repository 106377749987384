function socialSlider() {
    jQuery('.social-slider').slick({
		autoplay: true,
  		autoplaySpeed: 5000,
		speed: 750,
		slidesToShow: 1,
  		slidesToScroll: 1,
		arrows: true,
		appendArrows: '.social-nav',
		prevArrow: '<div><i class="icon-arrow-left"></i></div>',
        nextArrow: '<div><i class="icon-arrow-right"></i></div>',
		dots: false,
		infinite: true
	});
}
