function openChat() {

    var $offsetV, $offsetH;

    $zopim(function() {
        $zopim.livechat.window.hide();
    });

    jQuery(document).on('click', '.site-chat', function(ev) {
        ev.preventDefault();

        jQuery(this).toggleClass('open');
        $zopim.livechat.window.toggle();
    });

}
