function homeBlockHeight() {

    if (jQuery(window).width() > 992) {

        var rightHeight = jQuery('.product-category-right').outerHeight();
        var leftbottomHeight = jQuery('.product-category-left__bottom').outerHeight();
        var remainingHeight = rightHeight - leftbottomHeight;

        jQuery('.product-category-left__top').outerHeight('auto');

    } else {

        jQuery('.product-category-left__top').outerHeight('auto');

    }

}
