function scrollSite() {

    jQuery("#site-scroll").click(function(ev){
        ev.preventDefault();

        var html_doc = jQuery(window);
        var wh = html_doc.outerHeight();
        var ot = html_doc.scrollTop();

        var scroll_offset = 0;

        if (jQuery(this).hasClass("from-bottom")) {
            scroll_offset = ot - wh < 0 ? 0 : ot - wh;
        } else {
            scroll_offset = ot + wh;
        }

        jQuery("html, body").stop().animate({
            scrollTop: scroll_offset
        }, {
            duration: 800
        });
    });
}
