function jumbotronSwitcher() {

    var ind = 0;

    jQuery('.jumbotron__nav').on('click', 'a', function(ev) {
        ev.preventDefault();
        ind = jQuery(this).parent().index();

        jQuery('.jumbotron__nav li').removeClass('active').eq(ind).addClass('active');
        if ( jQuery('.jumbotron__slide').hasClass('active') ) {
            jQuery('.jumbotron__slide.active').addClass('animate-out').delay(800).queue(function(next) {
                jQuery(this).removeClass('animate-out active');
                jQuery('.jumbotron__slide').eq(ind).removeClass('animate-in');
                next();
            });
            jQuery('.jumbotron__slide').eq(ind).addClass('animate-in active');
        }
    });

}
