/* ========================================================
	Slider
======================================================== */

function teamSliderAbout() {

    jQuery('.teamslider').slick({
		autoplay: true,
  		autoplaySpeed: 5000,
		speed: 750,
        arrows: false,
		fade: true,
        dots: true,
        infinite: true,
		appendDots: jQuery(".team-slider-navigation"),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    adaptiveHeight: true
                }
            }
        ]
    });

}

function reviewSliderHome() {

    jQuery('.review-slider').slick({
		autoplay: true,
  		autoplaySpeed: 5000,
		speed: 1000,
        arrows: false,
        dots: true,
		cssEase: 'ease-in-out',
        infinite: true,
		appendDots: jQuery(".review-navigation")
    });

}

function aboutImageSlider() {

    jQuery(document).on('click', '.map-point', function() {
		var datapoint = jQuery(this).attr('data-point');

        jQuery('.slider-text__summary').removeClass('active');
        jQuery('.slider-text__summary[data-holder="' + datapoint + '"]').addClass('active');
	});

}

/* ========================================================
 Animation Circles
 ======================================================== */

function animateCircles() {

    setTimeout(function() {

        jQuery('.circle--asp').addClass("animate-circle");

    }, 300);

    setTimeout(function() {

        jQuery('.circle--bluewave').addClass("animate-circle");

    }, 1900);

    setTimeout(function() {

        jQuery('.circle--ftg').addClass("animate-circle");

    }, 3500);

    setTimeout(function() {

        jQuery('.circle--asp').removeClass("animate-circle");

    }, 1200);

    setTimeout(function() {

        jQuery('.circle--bluewave').removeClass("animate-circle");

    }, 2800);

    setTimeout(function() {

        jQuery('.circle--ftg').removeClass("animate-circle");

    }, 4400);

    setInterval(function() {

        setTimeout(function() {

            jQuery('.circle--asp').addClass("animate-circle");

        }, 300);

        setTimeout(function() {

            jQuery('.circle--bluewave').addClass("animate-circle");

        }, 1900);

        setTimeout(function() {

            jQuery('.circle--ftg').addClass("animate-circle");

        }, 3500);

        setTimeout(function() {

            jQuery('.circle--asp').removeClass("animate-circle");

        }, 1200);

        setTimeout(function() {

            jQuery('.circle--bluewave').removeClass("animate-circle");

        }, 2800);

        setTimeout(function() {

            jQuery('.circle--ftg').removeClass("animate-circle");

        }, 4400);

    }, 6000);

}
