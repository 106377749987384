function animateGrade(element) {

	// progressbar.js@1.0.0 version is used
	// Docs: http://progressbarjs.readthedocs.org/en/1.0.0/
	var amount = jQuery(element).attr('data-value');
	var animateAmount = amount / 10;

	var bar = new ProgressBar.Circle(element, {
	  color: '#fff',
	  // This has to be the same size as the maximum width to
	  // prevent clipping
	  strokeWidth: 13,
	  trailWidth: 1,
	  easing: 'easeInOut',
	  duration: 1400,
	  text: {
	    autoStyleContainer: false
	  },
	  from: { color: '#42a7db', width: 13 },
	  to: { color: '#42a7db', width: 13 },
	  // Set default step function for all animate calls
	  step: function(state, circle) {
	    circle.path.setAttribute('stroke', state.color);
	    circle.path.setAttribute('stroke-width', state.width);

	    var value = Math.round(circle.value() * 100);
	    if (value === 0) {
	      circle.setText('');
	    } else {
	      circle.setText(value);
	    }

	  }
	});
	bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
	bar.text.style.fontSize = '2rem';

	bar.animate(animateAmount);  // Number from 0.0 to 1.0

}
