/* ========================================================
	Sidebar click mobile
======================================================== */

function expandSidebar() {

    jQuery('.sidebar-navigation__title').on('click', 'span', function() {
        // Open class to parent
        jQuery(this).parent().toggleClass('open');
        // Expand inner
        jQuery(this).parent().next().slideToggle(400);
    });

}

/* ========================================================
	Action Slider
======================================================== */

function actionSliderPage() {

    jQuery('.action-slider--page').slick({
		autoplay: true,
  		autoplaySpeed: 5000,
		speed: 750,
        arrows: false,
        vertical: true,
        dots: true,
        infinite: true,
		appendDots: jQuery(".action-slider-navigation")
    });

}

/* ========================================================
	Review Slider
======================================================== */

function reviewSlider() {

    jQuery('.review__slider').slick({
		autoplay: true,
  		autoplaySpeed: 5000,
		speed: 750,
        arrows: false,
        dots: true,
        infinite: true,
        adaptiveHeight: true
    });

}
