function add_marker( $marker, map, infobox ) {

    var latlng      = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') ),
        markerImage = jQuery('.hero--map').attr('data-marker'),
        marker      = new google.maps.Marker({
            position : latlng,
            map		 : map,
            icon 	 : markerImage
        }),
        ibTimeout;

    map.markers.push(marker);

    var contentString = $marker.html();

    google.maps.event.addListener(marker, "mouseover", function (e) {
        infobox.setContent(contentString);
        infobox.open(map, this);
        infobox.addListener("domready", function() {
            jQuery('.info-window').on('click', function() {
                var location = jQuery(this).attr('data-location'),
                    picker   = jQuery('.location-picker a[data-location='+ location +']'),
                    current  = jQuery('.sidebar-location__block[data-location='+ location +']');

                jQuery('.location-picker a').not(picker).removeClass('active');
                picker.addClass('active');
                jQuery('.sidebar-location__block').not(current).removeClass('active');
                current.addClass('active');

                jQuery('html, body').stop().animate({
                    scrollTop: jQuery('.default__holder').offset().top - jQuery('#site-header').outerHeight()
                }, {
                    duration: 800
                });
            });
        });
    });

    google.maps.event.addListener(marker, "mouseout", function (e) {
        ibTimeout = setTimeout(function() {
            infobox.close();
        }, 250);
    });

    google.maps.event.addListener(infobox, 'domready', function(e) {
        jQuery('.info-window').on('mouseenter', function(e) {
            clearTimeout(ibTimeout);
        }).on('mouseleave', function(e){
            clearTimeout(ibTimeout);
            infobox.close();
        });
    });

}

function center_map( map ) {

    var bounds = new google.maps.LatLngBounds();

    jQuery.each( map.markers, function( i, marker ){
        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
        bounds.extend( latlng );
    });

    if( map.markers.length === 1 ) {
        map.setCenter( bounds.getCenter() );
        map.setZoom( 15 );
    } else {
        map.fitBounds( bounds );
    }

}

function initMap(element) {

    var map,
        el       = jQuery(element),
        $markers = jQuery('.hero--map').find('.marker'),
        infobox  = new google.maps.InfoWindow({
            disableAutoPan: false,
            maxWidth: 280,
            pixelOffset: new google.maps.Size(30, -40),
            zIndex: null,
            boxStyle: {
                width: "280px"
            },
            closeBoxMargin : "12px",
            infoBoxClearance: new google.maps.Size(1, 1),
            enableEventPropagation: false
        }),
        drag;

    if (MQ === 'desktop') {
        drag = true;
    } else {
        drag = false;
    }

    function initialize() {
        var mapOptions = {
            zoom: 15,
            scrollwheel: false,
            draggable: drag,
            disableDefaultUI: true,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP]
            }
        };

        // init MAP
        map = new google.maps.Map(jQuery(element).get(0), mapOptions);

        map.markers = [];

		// add markers
		$markers.each(function(){
	    	add_marker(jQuery(this), map, infobox);
		});

		center_map(map);

    }

    initialize();

    google.maps.event.addDomListener(window, 'resize', function() {
        var getCen = map.getCenter();
        google.maps.event.trigger(map, "resize");
        map.setCenter(getCen);
    });

}
