function initAccordion() {

    const headers = document.querySelectorAll('.accordion-block .accordion__title');

    function openCurrAccordion() {

        for (let i = 0; i < headers.length; i += 1) {
            const parent = headers[i].parentElement;
            const article = headers[i].nextElementSibling;

            if (this === headers[i] && !parent.classList.contains('open')) {
                parent.classList.add('open');
                article.style.maxHeight = `${article.scrollHeight}px`;
            } else {
                parent.classList.remove('open');
                article.style.maxHeight = '0px';
            }
        }
    }

    for (let i = 0; i < headers.length; i += 1) {
        headers[i].addEventListener('click', openCurrAccordion);
    }
};
