function logoSlider() {
    jQuery(".footer-slider").slick({
        autoplay: true,
		autoplaySpeed: 5000,
		speed: 750,
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
		centerMode: true,
		centerPadding: '0px',
        prevArrow: '#footer-slider--prev',
        nextArrow: '#footer-slider--next',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}
