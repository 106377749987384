function productSlider() {

    jQuery('.product-slider').slick({
		autoplay: true,
  		autoplaySpeed: 5000,
		speed: 750,
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 3,
        appendArrows: jQuery('.product-slider-navigation'),
        arrows: true,
        prevArrow: '<div><i class="icon-arrow-left"></i></div>',
        nextArrow: '<div><i class="icon-arrow-right"></i></div>',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

}
