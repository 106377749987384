function actionSlider() {

    jQuery('.action-slider--header').slick({
		autoplay: true,
  		autoplaySpeed: 5000,
		speed: 750,
        arrows: false,
        vertical: true,
        dots: true,
        infinite: true
    });

}
