/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    // encapsulate variables that need only be defined once
    var pl = /\+/g,  // Regex for replacing addition symbol with a space
        searchStrict = /([^&=]+)=+([^&]*)/g,
        searchTolerant = /([^&=]+)=?([^&]*)/g,
        decode = function (s) {
            return decodeURIComponent(s.replace(pl, " "));
        };

    // parses a query string. by default, will only match good k/v pairs.
    // if the tolerant option is truthy, then it will also set keys without values to ''
    $.parseQuery = function (query, options) {
        var match,
            o = {},
            opts = options || {},
            search = opts.tolerant ? searchTolerant : searchStrict;

        if ('?' === query.substring(0, 1)) {
            query = query.substring(1);
        }

        // each match is a query parameter, add them to the object
        while (!!(match = search.exec(query))) {
            o[decode(match[1])] = decode(match[2]);
        }

        return o;
    };

    // parse this URLs query string
    $.getQuery = function (options) {
        return $.parseQuery(window.location.search, options);
    };

    $.fn.parseQuery = function (options) {
        return $.parseQuery($(this).serialize(), options);
    };
}(jQuery));

/* ========================================================
 Check if mobile / desktop
 ======================================================== */

function deviceType() {
    //detect if desktop/mobile
    return window.getComputedStyle(document.querySelector(' body'), '::before').getPropertyValue('content').replace(/"/g, "").replace(/'/g, "");
}

var MQ = deviceType();

/* ----------------------------------------------------------------
 PLACEHOLDER / FIELDS
 ---------------------------------------------------------------- */

function initPlaceholder() {
    jQuery('[placeholder]').focus(function () {
        var input = jQuery(this);
        if (input.val() === input.attr('placeholder')) {
            input.val('');
            input.removeClass('placeholder');
        }
    }).blur(function () {
        var input = jQuery(this);
        if (input.val() === '' || input.val() === input.attr('placeholder')) {
            input.addClass('placeholder');
            input.val(input.attr('placeholder'));
        }
    }).blur().parents('form').submit(function () {
        jQuery(this).find('[placeholder]').each(function () {
            var input = jQuery(this);
            if (input.val() === input.attr('placeholder')) {
                input.val('');
            }
        });
    });
}

/* ----------------------------------------------------------------
 MATCH HEIGHT
 ---------------------------------------------------------------- */

function matchHeightByRow(selector) {
    jQuery(selector).unbind().matchHeight({
        byRow: true
    });
}

/* ========================================================
 Youtube click modal
 ======================================================== */

function youtubeVideoClick() {
    // JavaScript to be fired on the home page, after the init JS
    jQuery('a[href^="https://www.youtube.com"], a[href^="http://www.youtube.com"]').on('click', function (e) {
        // Store the query string variables and values
        // Uses "jQuery Query Parser" plugin, to allow for various URL formats (could have extra parameters)
        var queryString = jQuery(this).attr('href').slice(jQuery(this).attr('href').indexOf('?') + 1);
        var queryVars = jQuery.parseQuery(queryString);

        // console.log(queryVars);

        // if GET variable "v" exists. This is the Youtube Video ID
        if ('v' in queryVars) {
            // Prevent opening of external page
            e.preventDefault();

            // Variables for iFrame code. Width and height from data attributes, else use default.
            var vidWidth = 960; // default
            var vidHeight = 540; // default
            var videoId = queryVars.v;

            if (jQuery(this).attr('data-width')) {
                vidWidth = parseInt(jQuery(this).attr('data-width'));
            }
            if (jQuery(this).attr('data-height')) {
                vidHeight = parseInt(jQuery(this).attr('data-height'));
            }
            var iFrameCode = '<div class="embed-responsive embed-responsive-16by9"><iframe width="1280" height="720" scrolling="no" allowtransparency="true" allowfullscreen="true" src="//www.youtube.com/embed/' + videoId + '?rel=0&wmode=transparent&showinfo=0&autoplay=1" frameborder="0"></iframe></div>';

            // Replace Modal HTML with iFrame Embed
            jQuery('.modal.modal--youtube .modal-body').html(iFrameCode);

            // Open Modal
            jQuery('.modal.modal--youtube').modal();
        }
    });

    // Clear modal contents on close.
    // There was mention of videos that kept playing in the background.
    jQuery('.modal.modal--youtube').on('hidden.bs.modal', function () {
        jQuery('.modal.modal--youtube .modal-body').html('');
    });
}

/* ========================================================
 Welcome modal
 ======================================================== */
//
// function welcomeNewUser() {
//     var welcome_shown = Cookies.get('welcome_shown');
//     if (typeof welcome_shown === 'undefined')
// 	{
//         jQuery("#modal-welcome").modal();
//
// 		Cookies.set('welcome_shown', 'yes', { expires: 11000, path: '/' });
// 	}
// }

function scrollWebshop() {
    jQuery('.scrolltowebshop').on('click', function (event) {
        event.preventDefault();
        var headerHeight = jQuery('.site-header__inner').outerHeight();
        var breadcrumbsHeight = jQuery('#breadcrumbs').outerHeight();
        var totalHeight = headerHeight + breadcrumbsHeight;

        jQuery('html, body').animate({
            scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top - totalHeight
        }, 500);
    });
}

function scrollBranches() {

    jQuery('.scrolltobranches').on('click', function (event) {
        event.preventDefault();
        var headerHeight = jQuery('.site-header__inner').outerHeight();
        var breadcrumbsHeight = jQuery('#breadcrumbs').outerHeight();
        var totalHeight = headerHeight + breadcrumbsHeight;

        jQuery('html, body').animate({
            scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top - totalHeight
        }, 500);

    });

}

/* ========================================================
 Polyfills
 ======================================================== */

function renderPolyfills() {
    jQuery(".sf-field-search input").placeholder();
}

/* ========================================================
 Browserdebug
 ======================================================== */

function browserDebug() {
    // Make sure Firefox applies a repaint for some blocks
    jQuery.fn.matchHeight._afterUpdate = function (event, groups) {
        var ref_image = jQuery(".referentie-image");
        ref_image.css({"position": "static"});
        setTimeout(function () {
            ref_image.css({"position": "relative"});
        }, 1);
    };
}

function languageMenu(){
    jQuery('.site-header__right').on('click', '.lang-selector', function(e){
        jQuery('.site-header__right .lang-selector').toggleClass('active');
    });

}

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                openMenu();
                openSearch();
                matchHeightByRow('.match-me-sub');
                matchHeightByRow('.match-me');
                youtubeVideoClick();
                scrollSite();
                //welcomeNewUser();
                renderPolyfills();
                browserDebug();
                languageMenu();
                initAccordion();

                if (MQ === 'desktop') {
                    openChat();
                }

                if ($('.footer-slider').length > 0) {
                    logoSlider();
                }

                if ($('.sidebar-navigation').length > 0 && MQ === 'mobile') {
                    expandSidebar();
                }

                if ($('.action-slider--page').length > 0) {
                    actionSliderPage();
                }

                if ($('.review__slider').length > 0) {
                    reviewSlider();
                }
                if ($('.social-slider').length > 0) {
                    socialSlider();
                }

                $(window).resize(function () {
                    homeBlockHeight();
                });

            }
        },
        // Home page
        'home': {
            init: function () {
                jumbotronSwitcher();
                matchHeightByRow('.site-block--block');
                reviewSliderHome();
                homeBlockHeight();
            }
        },
        // Contact page
        'contact': {
            init: function () {
                initMap('.map');
                initPlaceholder();
                locationChange();
            }
        },
        'stel_een_vraag': {
            init: function () {
                initMap('.map');
                initPlaceholder();
                locationChange();

                var headerHeight = $('#site-header').outerHeight();

                $('html, body').animate({
                    scrollTop: $("#contactform-location").offset().top - headerHeight
                }, 2000);
            }
        },
        // About us page
        'page_template_about': {
            init: function () {
                teamSliderAbout();
                reviewSliderHome();
                aboutImageSlider();
                animateCircles();
            }
        },
        // Branches page
        'page_template_branches': {
            init: function () {
                scrollBranches();
            }
        },
        // Referenties single
        'single_referenties': {
            init: function () {
                if ($('#kwaliteit').length > 0) {
                    animateGrade('#kwaliteit');
                }
                if ($('#service').length > 0) {
                    animateGrade('#service');
                }
                if ($('#flexibiliteit').length > 0) {
                    animateGrade('#flexibiliteit');
                }
            }
        },
        // Producten single
        'single_producten': {
            init: function () {
                productSlider();
            }
        },
        // Page Webshop
        'page_template_webshop': {
            init: function() {
                scrollWebshop();
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
