function reviewSliderHome() {

    jQuery('.review-slider').slick({
		autoplay: true,
  		autoplaySpeed: 5000,
		speed: 1000,
        arrows: false,
        dots: true,
		cssEase: 'ease-in-out',
        infinite: true,
		appendDots: jQuery(".review-navigation")
    });

}
