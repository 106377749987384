function locationChange() {

    jQuery('.location-picker').on('click', 'a', function(ev) {
        ev.preventDefault();

        var location = jQuery(this).attr('data-location'),
            current  = jQuery('[data-location='+ location +']');

        jQuery('.location-picker a').not(jQuery(this)).removeClass('active');
        jQuery(this).addClass('active');
        jQuery('.sidebar-location__block').add('.sidebar-right__persons--contact').not(current).removeClass('active');
        current.addClass('active');
    });

}
