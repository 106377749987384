/* ========================================================
	Mobile menu click for submenu
======================================================== */

function showSubmenuMobile() {

    jQuery('.has-children').on('click', '.nav-main-link', function(ev) {
        ev.preventDefault();
        jQuery('.site-navigation__menu').addClass('open-submenu');
        jQuery(this).parent().addClass('show-submenu');
    });

}

function closeSubmenuMobile() {

    jQuery('.nav-main-item--parent').on('click', 'a', function(ev) {
        ev.preventDefault();
        jQuery('.site-navigation__menu').removeClass('open-submenu');
        jQuery(this).parents('.has-children').removeClass('show-submenu');
    });

}

/* ========================================================
	Desktop submenu
======================================================== */

function showSubmenu() {

    jQuery('.has-children').on('click', '.nav-main-link', function(ev) {
        ev.preventDefault();

        jQuery('.has-children').not(jQuery(this).parent()).removeClass('show-submenu');
        jQuery(this).parent().toggleClass('show-submenu');
    });

}

/* ========================================================
	Open menu click
======================================================== */

function openMenu() {

    jQuery('.site-header__menu-button').on('click', 'a', function(ev) {
        ev.preventDefault();

        var header = jQuery('#site-header');

        if ( header.hasClass('open-menu') ) {
            header.removeClass('open-menu');

            jQuery('.navigation-container').stop().animate({
                'top' : '-40px',
                'opacity' : '0'
            }, {
                duration: 600,
                complete: function() {
                    jQuery(this).hide();
                }
            });
        } else {
            header.addClass('open-menu');

            jQuery('.navigation-container').show().stop().animate({
                'top' : '0',
                'opacity' : '1'
            }, {
                duration: 800
            });

            actionSlider();
        }
    });

    if (MQ === 'mobile') {
        showSubmenuMobile();
        closeSubmenuMobile();
    } else {
        showSubmenu();
    }

}

/* ========================================================
	Search button click
======================================================== */

function openSearch() {

    jQuery('.site-header__search-button').on('click', 'a', function(ev) {
        ev.preventDefault();

        jQuery(this).parent().toggleClass('open');
    });

}
